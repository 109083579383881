.home-introduction {
    padding-top: 50px;
    width: 100%;
    background-color: #fff;

    > .content {
        width: 1200px;
        margin: 0 auto 54px auto;

        > .title {
            display: block;
            font-size: 26px;
            text-align: center;
            font-weight: bold;
            color: #222222;
            margin-bottom: 40px;
        }

        > .content-box {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            width: 100%;
            margin-bottom: 80px;

            > .content-box-item-box {
                height: 206px;
                position: relative;

                &:hover {
                    > .content-box-item-hover {
                        opacity: 1;
                        height: 236px;
                        transform: translateY(-8px) scaleY(1);
                    }

                    > .content-box-item {
                        transform: translateY(-15px);
                        opacity: 0;
                    }
                }

                > .content-box-item {
                    position: absolute;
                    width: 100%;
                    padding: 50px 0;
                    height: 206px;
                    opacity: 1;
                    border: 1px solid #ECECEC;
                    transition: .3s;

                    > .img-icon-box {
                        margin: 0 auto;
                        width: 33px;

                        > img {
                            width: 100%;
                        }
                    }

                    > h2 {
                        user-select: none;
                        font-size: 18px;
                        color: #222222;
                        margin: 12px 0 0 0;
                        text-align: center;
                    }

                    > p {
                        user-select: none;
                        max-width: 140px;
                        margin: 20px auto 0 auto;
                        text-align: center;
                        font-size: 14px;
                        color: #666666;
                    }
                }

                > .content-box-item-hover {
                    //transform: translateY(-8px) scaleY(.9);
                    transition: .4s;
                    width: 100%;
                    position: absolute;
                    opacity: 0;
                    height: 206px;
                    border: 1px solid #ECECEC;

                    > .img-icon-box {
                        height: 68px;
                        background: linear-gradient(92deg, #0FA971 0%, #5CC653 100%);
                        width: 100%;
                        margin-bottom: 8px;

                        > img {
                            user-select: none;
                            width: 33px;
                        }

                        > h2 {
                            user-select: none;
                            font-size: 18px;
                            color: #fff;
                            margin-left: 8px;
                        }
                    }

                    > .text-list-box {
                        display: grid;

                        > p {
                            user-select: none;
                            max-width: 190px;
                            margin: 5px auto 0 auto;
                            text-align: center;
                            font-size: 14px;
                            color: #666666;
                        }
                    }

                    > .btn {
                        display: block;
                        margin: 10px auto 0 auto;
                        width: 85px;
                        height: 27px;
                        color: #2DAF61;
                        border: 1px solid #2DAF61;
                        border-radius: 4px;
                        background-color: transparent;
                    }

                    > a {
                        > .btn {
                            display: block;
                            margin: 10px auto 0 auto;
                            width: 85px;
                            height: 27px;
                            color: #2DAF61;
                            border: 1px solid #2DAF61;
                            border-radius: 4px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        > .content-img {
            width: 100%;
            display: flex;
            justify-content: space-between;

            > a {
                width: 49%;

                > img {
                    width: 100%;
                }
            }
        }
    }

    > .introduction {
        width: 100%;
        padding: 48px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        background-image: url('../../../images/home/beijing@2x.png');
        background-size: 100% 100%;

        .multi-end {
            width: 735.7px;
            height: 352.2px;
            background-image: url('../../../images/home/pingtaibeijing@2x.png');
            background-size: 100% 100%;
            text-align: center;
            display: grid;
            line-height: 175px;
            grid-template-rows: 10% 25%;
            margin-right: 65px;

            p {
                color: #fff;
                font-size: 22px;
                font-weight: 800;
            }

            > .logo {
                img {
                    width: 100px;
                    padding: 40px 15px;
                    margin: 0 30px;
                }
            }
        }

        .introduction-right {
            padding: 40px 0;
            width: 440px;

            > .t-1 {
                > p {
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFEFE;

                    > span {
                        font-weight: bold;
                        font-size: 24px;
                        color: #2DAF61;
                    }
                }
            }

            > .t-2 {
                margin: 40px 0 40px 0;

                > p {
                    font-size: 16px;
                    color: #999999;
                }
            }

            > .t-3 {
                display: flex;
                align-items: center;

                > p {
                    position: relative;
                    top: 3px;
                    color: #fff;
                    font-size: 14px;
                    margin-right: 8px;
                }

                > img {
                    width: 24px
                }
            }
        }
    }
}
