@charset "utf-8";
html {
    background-color: #fff;
    color: #000;
    font-size: 14px
}

@font-face {
    font-family: 'PingFang';  /* Project id 1480761 */
    src: url('https://static.mobi2077.com/lib/PingFang%20Medium_0.ttf');
}

body, ul, ol, dl, dd, h1, h2, h3, h4, h5, h6, figure, form, fieldset, legend, input, textarea, button, p, blockquote, th, td, pre, xmp, a {
    margin: 0;
    padding: 0;
}

body, input, textarea, button, select, pre, xmp, tt, code, kbd, samp {
    line-height: 1.5;
}

.antd-button-sm {
    height: 28px;
    line-height: 14px;
}

/* h1,h2,h3,h4,h5,h6,small,big,input,textarea,button,select{font-size:100%} */
h1, h2, h3, h4, h5, h6 {
    margin: 3px 0;
}

h1, h2, h3, h4, h5, h6, b {
    font-weight: normal
}

address, cite, dfn, i, optgroup, var {
    font-style: normal
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left
}

caption, th {
    text-align: inherit
}

/* ul,ol,menu{list-style:none} */
fieldset, img {
    border: 0
}

img, object, input, textarea, button, select {
    vertical-align: middle
}

article, aside, footer, header, section, nav, figure, figcaption, hgroup, details, menu {
    display: block
}

audio, canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1
}

blockquote:before, blockquote:after, q:before, q:after {
    content: "\0020"
}

textarea {
    overflow: auto;
    resize: vertical
}

input, textarea, button, select, a {
    outline: 0 none;
    border: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    padding: 0;
    border: 0
}

mark {
    background-color: transparent
}

a, ins, s, u, del {
    text-decoration: none
}

sup, sub {
    vertical-align: baseline
}

html {
    overflow-x: hidden;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    font-family: PingFang;
    width: 100%;
    min-width: 1200px;
    color: #333;
    -webkit-text-size-adjust: none;
}

hr {
    height: .02rem;
    margin: .1rem 0;
    border: medium none;
    border-top: .02rem solid #cacaca;
}


a {
    color: #333;
    text-decoration: none;
}

img {
    width: 100%;
}

.one-text-flow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ql-align-center {
    text-align: center;
}

.ant-modal-body {
    min-height: 0px !important;
}

.ant-popover-buttons {
    display: none;
}

.ant-popover-message-title {
    padding: 0;
}

.ant-image-preview-img {
    width: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.ant-collapse-content-box {
    padding: 0 16px !important;
}

.ant-modal.buy-payment-modal .ant-modal-content .ant-modal-body {
    background-color: #F0F0F0;
    padding-top: 42px;
}

.ant-popover.ant-popover-placement-rightTop .ant-popover-content .ant-popover-arrow {
    display: none !important;
}

.ant-popover.ant-popover-placement-rightTop .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 0 !important;
}

.ant-popover.ant-popover-placement-left .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 0 !important;
}

.ant-popover.ant-popover-placement-bottomRight .ant-popover-content .ant-popover-arrow {
    display: none !important;
}

.ant-popover.ant-popover-placement-bottomRight .ant-popover-content .ant-popover-inner {
    position: relative;
    background-color: transparent;
    top: -12px;
}

.ant-popover.ant-popover-placement-bottomRight .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 0 !important;
}

.ant-popover.ant-popover-placement-right .ant-popover-content .ant-popover-arrow {
    border-color: transparent #282b30 #282b30 transparent;
    transform: rotate(135deg) !important;
    transform-origin: top;
    left: 9px;
}

.ant-popover.ant-popover-placement-right .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 0 !important;
}

.ant-popover.ant-popover-placement-top .ant-popover-content .ant-popover-arrow {
    border-color: transparent #282b30 #282b30 transparent;
}

.ant-popover.ant-popover-placement-top .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 0 !important;
}

.ant-modal.promoted-modal .ant-modal-content {
    position: relative;
    top: 20px;
    background: #F5F5F5;
    border-radius: 20px;
}

.ant-modal.promoted-modal .ant-modal-footer {
    display: none;
}

.shop-setting-modal, .shop-setting {
    display: grid;
    justify-items: center;
}

.help-type-cur {
    color: #2DAF61
}

.help-nav-cur {
    transition: .33s;
    display: flex;
    align-items: center;
    color: #2DAF61;
}

.help-nav-cur > i {
    font-size: 12px;
    margin-right: 3px;
}

.f14 {
    font-size: 14px;
}

.f16 {
    font-size: 16px;
}

.pint {
    cursor: pointer;
}

.flex {
    display: flex;
}

.jtyct {
    justify-content: center;
}

.jtyspbe {
    justify-content: space-between;
}

.ait {
    align-items: center;
}

.text-ov1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #626366;
}

.text-ov2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.text-ov3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.help-hover-nav {
    position: relative;
    transition: .33s;
    border-left: 2px solid #2DAF61;
    padding: 0 0 0 10px;
    font-weight: 700;
}

.help-hover-nav:hover {
    color: #2DAF61;
}

.qrcode-mask {
    display: grid;
    grid-template-rows: 100px 50px;
    text-align: center;
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
}

.security-settings-modal-box {
    padding: 4px;
    max-height: 600px;
    overflow-y: scroll;
}

.put-tab-img {
    position: relative;
}

.put-tab-img::after {
    position: absolute;
    display: block;
    content: '';
    top: -4px;
    left: 14px;
    width: 82px;
    height: 60px;
    border: 1px solid #f09329;
}

/*body {*/
/*    overflow: overlay;*/
/*}*/
