.menu-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f5f5f5;
  height: 64px;
  line-height: 64px;
  text-align: center;
  padding-right: .5rem;
  > .menu-icon {
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
  }
  &.current {
    background-color: #fff;
    p {
      color: #2DAF61;
    }
  }
  &.current::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 3px;
    height: 64px;
    background-color: #2DAF61;
  }
  &.current::after {
    content: '';
    position: absolute;
    left: 3px;
    display: block;
    border: 6px solid transparent;
    border-left: 6px solid #2DAF61;
  }
  > p {
    font-size: 16px;
    color: #666666;
  }
}