.main-pop {
  width: 285px;
  >.user-head {
    display: flex;
    background-color: #313a45;
    padding: 20px 15px;
    >.avatar {
      margin: 0 5px;
      > .avatar-icon {
        width: 65px;
        border-radius: 50%;
      }
    }
    > .nickname-logout {
      position: relative;
      padding: 5px 0px 3px 10px;
      > .nickname {
        color: rgb(226, 225, 225);
        font-size: 15px;
      }
      > .logout {
        cursor: pointer;
        position: absolute;
        bottom: 2px;
        color: #4773c8;
      }
      > .logout:hover {
       
        color: #fc9a2b;
      }
    }
  }
  > .user-balance {
    background-color: #3a4452;
    padding: 15px 3px 12px 3px;
    > .balance-val {
      color: #989898;
      text-align: center;
    } 
    > .balance-btn {
      display: flex;
      justify-content: center;
      margin: 6px 0 10px 0;
      > .recharge, > .withdraw {
        height: 22px;
        color: #fff;
        line-height: 22px;
        text-align: center;
        padding: 0 6px;
        font-size: 12px;
        width: 76px;
        cursor: pointer;
        border-radius: 2px;
        margin: 0 9px;
        vertical-align: middle;
      }
      > .recharge {
        background-color: #4773C8;
      }
      > .withdraw {
        background-color: #45536C;
      }
      > .recharge:hover {
        background-color: #5e80c5;
      }
      > .withdraw:hover {
        background-color: #515f77;
      }
    }
  }
}