.mobi-manag {
  width: 100%;
  padding: 22px;
  box-sizing: border-box;

  .mobi-container {
    position: static;

    > .mobi-reward {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 10px;
      width: 100%;
      margin-top: 10px;

      .mobi-item {
        padding: 35px 0 0 75px;
        background-size: cover;
        box-sizing: border-box;
        height: 123px;
        color: #fff;
        border-radius: 4px;

        .title {
          font-size: 14px;
        }

        .mobi-value {
          margin-top: 6px;
          color: #fff;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }

    > .mobi-prompt {
      width: 100%;
      margin-top: 30px;
      border: 3px dashed #e2e2e2;
      padding: 20px 32px;
      box-sizing: border-box;

      .prompt-v {
        color: #333333;
        font-size: 14px;
      }
    }

    > .mobi-link {
      display: flex;
      margin: 3rem 0 2rem 1rem;

      .mobi-btn {
        cursor: pointer;
        width: 208px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        border-radius: 4px;
        background-color: #2EB062;
        color: #fff;
        font-size: 14px;
        margin-right: 20px;
      }

      .mobi-btn:hover {
        color: #E0FFEC;
        background-color: #47b372;
      }

      > .mobi-record {
        cursor: pointer;
        color: #2EB062;
        font-size: 14px;
        display: flex;
        align-items: center;

        .icon-arrow-right {
          font-size: 1rem;
        }
      }

    }
  }

}

.mobi-nav-c {
  position: relative;
  height: 18px;
  border-bottom: 2px solid #2DAF61;

  .mobi-nav {
    position: absolute;
    bottom: 0px;
    display: flex;

    > span {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 23px;
      height: 33px;
      background-color: #2DAF61;
      font-size: 14px;
      color: #fff;

      &.active {
        background-color: #E0FFEC;
        color: #6D7971;
      }
    }
  }
}

.mobi-table-c {
  position: relative;
}

.ant-modal-body {
  min-height: 400px;
}

