.roll-room-prize-items-container {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 10px;
    width: 100%;
    padding: 10px 10px 0 10px;
    background: #F5F5F5;

    > .item {
        list-style: none;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        margin-bottom: 10px;

        > .item-cover-box {
            width: 100%;
            height: 68px;

            > .cover {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                box-sizing: border-box;

                > img {
                    width: 100%;
                    height: 100%;
                }

                h4 {
                    padding: 2px 3px;
                    margin: 0;
                    font-size: 12px;
                }

                > .special {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #fff;
                    background: #222;
                }

                > .cover-top-info {
                    transform-origin: top left;
                    transform: scale(.9);
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;

                    > .rarity {
                        background-color: #2A2A2A;
                        color: #fff;
                    }

                    > .exterior {
                        color: #fff;
                    }
                }
            }
        }

        > .item-text-info {
            line-height: 16px;
            height: 48px;
            padding: 5px 6px 6px 6px;

            > h4 {
                width: 91px;
                font-size: 14px;
                font-weight: 400;
                color: #222222;
            }

            > p {
                font-size: 16px;
                font-weight: 400;
                color: #FF8801;
            }
        }

        > .item-user-info {
            padding: 6px 4px;
            display: flex;
            align-items: center;
            width: 100%;
            background-color: #E7E7E7;

            > img {
                width: 30px;
                height: 30px;
                border: 2px solid #fff;
                border-radius: 50%;
                margin-right: 5px;
            }

            > p {
                font-size: 12px;
                width: 60px;
            }
        }
    }
}
