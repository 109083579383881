.withdraw {
  width: 100%;
  padding: 3rem;
  box-sizing: border-box;

  > .withdraw-amount {
    width: 100%;

    > .withdraw-line {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      > .line-left {
        height: 100%;
        width: 10%;
        display: flex;
        justify-content: flex-end;
        font-weight: 400;
      }

      > .receive {
        align-items: flex-start;
        width: 10%;
        height: 100%;
      }

      > .line-right {
        width: 90%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;

        .yl-con {
          margin-top: 10px;
          width: 100%;
          height: 43px;
          background: #F5F5F5;
          border: 1px solid #E5E5E5;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 0 20px;

          .ant-input {
            width: 150px;
          }

          > .yl-fill {
            height: 100%;

            > .has-fill {
              height: 100%;
              display: flex;
              align-items: center;

              > span {
                cursor: pointer;
                color: #2DAF61;
              }

              > .fill-item {
                display: flex;
                width: 30%;

                > span {
                  display: flex;
                  align-items: center;
                  color: #999999;
                }
              }
            }
          }

          > .zfb-fill {
            height: 100%;

            > .has-fill {
              height: 100%;
              display: flex;
              align-items: center;

              > span {
                cursor: pointer;
                color: #2DAF61;
              }

              > .fill-item {
                display: flex;
                width: 40%;

                > span {
                  display: flex;
                  align-items: center;
                  color: #999999;
                }
              }
            }
          }
        }

        .type-item {
          > .icon-type {
            height: 46px;
            width: auto;
          }

          > .icon-zfb-tj {
            position: relative;
            display: inline-block;
            top: -18px;
            left: 4px;
            font-size: 12px;
            padding: 2px 4px;
            transform: scale(.8);
            color: #fff;
            background-color: #f4aa47;
            border-radius: 4px;
          }
        }
      }
    }
  }

  > .withdraw-operating {
    padding-left: 20px;

    > .op-fee {
      display: flex;
      align-items: center;

      > .title {
        font-size: 1.2rem;
        font-weight: 500;
        margin-right: 20px;

        > span {
          font-size: 1.6rem;
          color: #FF9E40;
        }
      }
    }


    > .withdraw-send {
      margin-top: 20px;
      cursor: pointer;
      border-radius: 4px;
      padding: .6rem .95rem;
      background-color: #2EB062;
      color: #fff;
      font-size: 1.1rem;
    }
  }

  > .withdraw-prompt {
    margin-top: 35px;
    width: 90%;
    background-size: 100% 100%;
    border: 3px dashed #e2e2e2;
    padding: 20px 50px;
    box-sizing: border-box;

    .prompt-v {
      color: #343434;
      font-size: 14px;
      margin: 6px 0;
    }
  }

  > .withdraw-link {
    margin: 30px 0 20px 10px;
    cursor: pointer;
    color: #2EB062;
    font-size: 16px;

    .icon-arrow-right {
      font-size: 14px;
    }
  }
}
