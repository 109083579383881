.mobi-item-filter-select {
  > div {background: transparent; max-height: 0px;}
  .ant-cascader-menu {
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0,  .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0,  .05);
  } 
  .ant-cascader-menu:nth-child(2)  {
    max-height: 400px;
  }
}