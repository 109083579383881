.home-banner {
    position: relative;
    width: 100%;

    .ant-carousel {
        max-height: 522px !important;

        img {
            width: 100% !important;
        }
    }

    > .banner-container {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #000;

        .banner-item {
            position: absolute;
            top: 0;
            right: 0.1%;
            width: 100%;
            height: 100%;
            transition: 1s;
            opacity: 0;

            img {
                width: 100%;
                // height: 100%;
            }

            &.active {
                opacity: 1;
            }
        }
    }

    > .indecation {
        position: absolute;
        bottom: 8%;
        width: 100%;
        display: flex;
        justify-content: center;

        .ind-item {
            cursor: pointer;
            display: block;
            width: .9rem;
            height: .9rem;
            background-color: #fff;
            border-radius: 50%;
            margin: 0 .3rem;

            &.active {
                width: 2rem;
                background-color: #2DAF61;
                border-radius: .4rem;
                transition: .5s width;
            }
        }
    }

    > .banner-bottom-tips {
        width: 100%;
        background-color: #dadada;
        padding: 10px 0 10px 10%;
    }
}

