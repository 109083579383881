.rollRoom-content-box-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    min-height: 320px;

    &.welfare {
        grid-template-columns: 1fr 1fr;
    }

    > .rollRoom-item {
        position: relative;
        background-color: #F2F2F2;
        margin-bottom: 15px;

        > .top-info {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 24px;
            height: 83px;
            background-image: url("../../../images/rollRoom/roll fang beijing@2x.png");
            background-size: cover;

            > .avatar {
                width: 57px;
                height: 57px;
                border-radius: 50%;
                border: 2px solid #fff;
                margin-right: 10px;
                background-image: url("../../../images/rollRoom/hei@3x.png");
                background-size: cover;
            }

            > .room-title {
                > h2 {
                    width: 257px;
                    color: #CDCFDD;
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 6px;
                }

                > span {
                    transform: scale(.8);
                    transform-origin: right top;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 12px;
                    padding: 0 10px;
                    border-radius: 12px;
                }


                > p {
                    font-weight: bold;
                    color: #FFFFFF;
                    font-size: 13px;

                    > span {
                        color: #FF6A1F;
                    }
                }
            }

            &.welfare {
                background-image: url("../../../images/rollRoom/fulifang beijing@2x.png");

                > .avatar {
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    margin-right: 10px;
                    background-image: url("../../../images/rollRoom/hei@3x.png");
                    background-size: cover;
                }

                > .room-title {
                    > h2 {
                        width: 257px;
                        color: #fff;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 6px;
                    }

                    > div {
                        > span {
                            font-size: 12px;
                            padding: 1px 17px;
                            border-radius: 12px;
                        }

                        > p {
                            font-weight: bold;
                            color: #FFFFFF;
                            font-size: 13px;

                            > span {
                                color: #FF6A1F;
                            }
                        }
                    }
                }
            }
        }

        > .detail-info {
            padding: 20px 15px;

            > .progress-line {
                margin: 20px 10px 20px 0;

                > span {
                    display: inline-block;
                    font-size: 14px;
                    color: #666666;
                    font-weight: bold;
                    margin-right: 10px;
                }

                > .progress-bar-container {
                    position: relative;
                    width: 85%;
                    height: 24px;
                    background: #FFFFFF;
                    border-radius: 11px;
                    padding: 2px 2px;

                    > .progress-bar {
                        //left: 2px;
                        width: 100%;
                        height: 100%;
                        border-radius: 9px;
                        overflow: hidden;
                        position: relative;
                        > .bar-main {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 0;
                            transition: width .5s ease-out;
                            height: 100%;
                            background-color: #FFB910;
                        }
                    }

                    > .progress-bar-val {
                        width: 100%;
                        display: block;
                        font-size: 16px;
                        text-align: center;
                        font-weight: bold;
                        position: absolute;
                        color: #F75631;
                    }
                }
            }

            > .item-price {
                font-size: 14px;
                font-weight: bold;
                color: #A9A9A9;
                line-height: 7px;

                > span {
                    font-size: 20px;
                    font-weight: bold;
                    color: #FFB10B;
                    line-height: 5px;
                }
            }

            > .more-item {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                align-items: center;
                padding: 10px;
                width: 100%;
                height: 83px;
                background-color: #dedfe0;
                margin: 20px 0;

            }

            > .bottom-info {
                font-size: 14px;
                font-weight: bold;
                color: #575757;
                line-height: 18px;
            }

            > .room-box-btn {
                position: absolute;
                bottom: 20px;
                right: 23px;
            }
        }
    }
}
