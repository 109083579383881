.top-nav {
    display: flex;
    justify-content: center;
    max-width: 1920px;
    margin: 0 auto;
    background-color: #1a1922;
    box-sizing: border-box;
    height: 44px;

    > .top-nav-box {
        //min-width: 900px;
        > .top-nav-item {
            color: #7B7B7B;
            font-weight: 600;
            font-size: 15px;
            margin-right: 40px;
            line-height: 44px;

            &.current {
                color: #2DAF61;
            }
        }
    }
}
