.c-nav {
    position: absolute;
    right: 0;
    left: 0;
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .nav-logo {
        width: 100px;
        margin-right: 33px;

        img {
            width: 100%
        }
    }

    .nav-container {
        width: 873px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .nav-item {
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #b4b4b4;
            padding: 20px 16px;
            font-weight: 700;
            height: 100%;

            &.current {
                color: #2DAF61;
                background-color: rgba(46, 46, 50, .5);
                border-bottom: 3px solid #2DAF61;
            }
        }
    }

    .nav-profile {
        display: flex;
        align-items: center;

        .main-msg {
            position: relative;
            cursor: pointer;
            width: 18px;
            margin: 0 15px 0 20px;

            .news-tips {
                display: block;
                position: absolute;
                bottom: 2px;
                right: -2px;
                width: 8px;
                height: 8px;
                background-color: #FF4C20;
                border-radius: 50%;
            }
        }

        .login, .main-info {
            display: flex;
            cursor: pointer;
            align-items: center;

            > .avatar {
                position: relative;
                margin: 0 5px;

                > .avatar-icon {
                    position: relative;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    border: 2px solid #989898;

                    &.online {
                        border: 2px solid #2DAF61;
                    }
                }
            }

            > .avatar::before {
                z-index: 10;
                display: block;
                content: '';
                position: absolute;
                bottom: 3px;
                right: 0px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #989898;
            }

            > .avatar.online::before {
                background-color: #2DAF61;
            }

            > p {
                cursor: pointer;
                font-size: 15px;
                margin-left: 5px;
                color: #B4B4B4;
            }

            > p:hover {
                color: #fff;
            }
        }
    }
}
