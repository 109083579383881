.on-shelf-container {
  > .title-box {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    >.title {
      font-size: 18px;
      font-weight: 900;
    }
    >.success-price {
      position: absolute;
      right: 5px;
      display: flex; 
      align-items: center;
      > i {background-image: url('../../images/inventory/wenhaoxuanzhong@2x.png'); margin: 0 14px;
        width: 20px; height: 20px; display: block; background-size: contain; cursor: pointer;
      }
      .btn {
        width: 106px; height: 30px;color: #FFFFFF;font-size: 12px; cursor: pointer;
        line-height: 30px;text-align: center;background: #7d2fff;border-radius: 4px;
      }
    }
  }
  > .on-shelf-item-container {
    min-height: 400px;
    max-height: 500px;
    .ant-table-body::-webkit-scrollbar {
      width: 6px;
      height: 1px;
    }
    .ant-table-body::-webkit-scrollbar-track {
      background: #D3D3D3;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
      background: #989898;
      border-radius: 0.4rem;
    }
  }
  > .on-shelf-bottom-bar {
    padding: 10px 25px 0 25px;
    box-sizing: border-box;
    position: relative;
     >.income {
      display: flex;
      margin-top: 10px;
      align-items: center;
      font-size: 16px;
      color: #999999;
      font-weight: 400;
      margin-bottom: 5px;
      > .all-price {
        font-size: 24px;
        color: rgb(240, 147, 41);
        > .item-num {
          font-size: 16px;
        }
      }
    }
    > .prompt {font-size: 16px; color: #525064;}
    >.merged-items {
      position: absolute;
      top: 40px;
      right: 165px;
    }
    > .on-shelf-btn { 
      position: absolute; right: 30px; top: 25px; cursor: pointer;
      width: 126px;height: 50px; color: #fff; font-size: 18px;
      text-align: center;line-height: 50px;
      background: #2DAF61;border-radius: 4px;
    }
  }
  
}