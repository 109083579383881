.dota2-items {
  // cursor: pointer;
  position: relative;
  width: 18.8%;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  ._img {
    cursor: pointer;
    position: relative;
    opacity: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    transition: 1s opacity;
    > .item-quality {
      position: absolute; left: 0; top: 0;  font-size: 12px; display: flex; align-items: center;
      > .item_quality{background-color: #2A2A2A;padding: 5px 8px; color: #b0c3d9;}
    }
    .item-img {
      width: 100%;
      height: 100%;
    }
  }
  .item-name {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: 500;
    margin: 10px 0 15px 0;
  }
  .item-name:hover {
    color: #2EB062;
  }
  .price {
    color: #FF8802;
    font-size: 18px;
    font-weight: 500;
    height: 24px;
    small {
      font-size: 16px;
    }
  }
  .end-time {
    position: absolute;
    bottom:12px;
    right: 10px;
    display: flex;
    color: #9A9A9A;
    align-items: center;
    .icon-time {
      width: 15px;
      height: 14px;
      margin-right: 6px;
    }
  }
  .item-select {
    position: absolute;bottom: 10px;right: 15px;
    width: 18px;height: 18px;border: 2px solid #CCCCCC;border-radius: 4px;
    .icon-hook-selected {display: none; font-weight: bold; transform: scale(.9); transform-origin: top left;}
    &.selected {
      border-color: #2DAF61;
      .icon-hook-selected {display: block;color: #2DAF61;}
    }
  }
}