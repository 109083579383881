.csgo-box {
  cursor: pointer;
  width: 100% !important;
  position: relative;
  padding: 0 !important;
  overflow: hidden;
  border-color: #E4E4E4;
  box-shadow: 0 0 4px 0 rgba(149, 149, 149, .3);
  >._img {
    width: 100%;
    height: 103.55px;
    background-image: url("../../../../../../images/items/item_bg.png");
    background-size: 100% 100%;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    >img {
      width: 75%;
    }
    >.subscript {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      padding: 0 2px;
      right: 0;
      top: 0;
      color: white;
      position: absolute;
    }
  }
  >.other-info {
    padding: 0 8px;
    .item-name {
      &:hover {
        transition: .2s all ease-out;
        color: #88B062;
      }
    }
  }
}
.initiate-con {
  >.acc-info {
    padding: 0 8px;
    display: flex;
    .icon-img {
      flex-shrink: 0;
      background-image: url("../../../../../../images/items/item_bg.png");
      background-size: 100% 100%;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      >img {
        width: 90%;
      }
    }
    >.acc-name {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 20px;
      color: #999999;
      p {
        display: flex;
        align-items: center;
      }
      >div {
        display: flex;
        >p span {
          font-size: 18px;
          color: #FF8801;
        }
      }
    }
  }
  >.initiate-config {
    padding: 20px;
    background-color: #F5F5F5;
    width: 100%;
    margin-top: 18px;
    min-height: 260px;
    >.config-item {
      margin: 20px 0;
      color: #999999;
      display: flex;
      align-items: center;
      >span {
        margin-right: 18px;
      }
    }
  }
  >.initiate-bottom {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    >.accounts {
      color: #999999;
      >span {
        color: #FF901A;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
